.about {
  padding: 80px 20px;
  background: linear-gradient(135deg, #F5F7F9 0%, #E0E4E8 100%);
}

.containere {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.text-content {
  flex: 1;
  padding: 20px;
  background: #ffffff; /* White background for text area */
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-top: 30px;
}

.text-content h1 {
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #007ACC;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.text-content p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.image-content {
  flex: 1;
}

.image-content img {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow for a modern look */
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .containere {
    flex-direction: column; /* Stack items vertically on smaller screens */
    gap: 20px; /* Reduced gap on smaller screens */
  }
}

@media (max-width: 992px) {
  .containere {
    text-align: center;
  }

  .text-content {
    padding: 20px;
    margin-top: 30px;
    background: #ffffff; /* Ensure background is white for consistency */
    border-radius: 8px; /* Slightly smaller border-radius for mobile */
  }

  .image-content img {
    width: 80%;
    margin-top: 20px; /* Add space above the image */
  }
}

@media (max-width: 768px) {
  .about {
    padding: 40px 10px; /* Reduce padding for smaller screens */
  }
  .text-content {
    margin-top: 50px;
 
  }
  .text-content h1 {
    font-size: 1.75rem; /* Adjust heading size for smaller devices */
  }

  .text-content p {
    font-size: 1rem; /* Adjust paragraph font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .text-content h1 {
    font-size: 1.5rem; /* Further reduce heading size for very small screens */
  }

  .text-content p {
    font-size: 0.875rem; /* Further reduce paragraph font size */
  }
}
