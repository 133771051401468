.not-found {
    text-align: center;
    padding: 50px;
  }
  
  .not-found h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .not-found p {
    font-size: 1.25rem;
  }
  
  .not-found ul {
    list-style: none;
    padding: 0;
  }
  
  .not-found li {
    margin: 10px 0;
  }
  
  .not-found a {
    color: #007bff;
    text-decoration: none;
  }
  
  .not-found a:hover {
    text-decoration: underline;
  }
  