/* Checkout.css */

.checkout {
  padding: 25px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkout h2 {
  color: #333;
  font-size: 30px;
  text-transform: capitalize;
  margin-top: 20px;
}

.order-summary {
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-summary h3 {
  color: #333;
  margin-bottom: 1rem;
}

.order-summary .mb-3 {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-summary .card-body {
  padding: 1rem;
}

.order-details {
  margin-top: 1rem;
}

.order-item {
  margin-bottom: 0.5rem;
}

.modal-content {
  border-radius: 8px;
}

.modal-header {
  border-bottom: none;
  color: white;
  background-color: #28a745;
}

.modal-footer {
  border-top: none;
}

.bg-success {
  background-color: #28a745 !important;
  border: none;
}

.btn-secondary {
  background-color: #6c757d;
  border: none;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.form-control {
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #ced4da;
  margin: 30px 0;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.form-label {
  color: #333;
}

.form-control-feedback {
  color: #dc3545;
}

.select-style {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ced4da;
}

.select-style:focus {
  border-color: #007bff;
}


hr {
  border: 0;
  border-top: 1px solid #ddd;
}

.toast {
  background-color: #28a745; /* Green for success messages */
}
.custom-tittle {
  font-size: 16px; /* Font size */
  color: #dc3545; /* Text color */
  margin-bottom: 15px; /* Space below the note */
  font-weight: bold; /* Make the text bold */
}
@media(max-width:768px){
  .custom-tittle {
    font-size: 12px; /* Font size */
  }
}
.textarea::placeholder{
  font-size: 16px;
}
@media(max-width:768px){
  .textarea::placeholder{
    font-size: 10px;
  }
}
.form-control {
  border-radius: 0.375rem; /* Modern rounded corners */
  border: 1px solid #ced4da; /* Light gray border */
  padding: 0.75rem 1.25rem; /* Adequate padding */
}

.form-control:focus {
  border-color: #007bff; /* Highlight border color on focus */
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25); /* Subtle shadow */
}

button.bg-success {
  background-color: #28a745;
  border-color: #28a745;
}

button.bg-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}
/* Checkout.css */

.order-summary {
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1.5rem;
}

.order-summary h3 {
  color: #007bff;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
}

.order-summary .order-item {
  background: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-summary .order-item:last-child {
  margin-bottom: 0;
}

.order-summary .order-item p {
  margin: 0;
  color: #333;
}

.order-summary .total-price {
  background: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 1rem;
  font-size: 1.25rem;
  text-align: center;
  margin-top: 1rem;
}
.textarea{
  text-transform: uppercase;
}
.order-summary .total-price p {
  margin: 0;
}
.custom{
  margin: 10px 0;
}

.price-tittle{
  color: red;
  font-size: 20px;
  font-weight: bold;
}.price-text{
  color: rgb(7, 7, 7);
  font-size: 20px;
  font-weight: bold;
}
.details-capital{
  text-transform: uppercase;
}
/* Error message styles */
.error-message {
  color: #d9534f; /* Red color for error messages */
  font-size: 0.875rem; /* Slightly smaller font size */
  margin-top: 0.5rem; /* Space above error message */
  display: flex;
  align-items: center;
}

/* Style for the error message container */
.error-message {
  display: flex;
  align-items: center;
  color: black; /* Bootstrap's red for errors */
  font-size: 0.875rem; /* Smaller font size for error messages */
}

/* Style for the error icon */
.error-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 1rem;
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

/* Style for the error text */
.error-text {
  line-height: 1.5;
}

/* Style for invalid input fields */
.form-control.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* Style for valid input fields */
.form-control.is-valid {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
