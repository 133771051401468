.Btn {
    width: 45px;
    height: 45px;
    background: linear-gradient(#44ea76, #39fad7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 20px;
    border: none;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0; /* Initially hidden */
    transform: translateY(10px); /* Initial off-screen position */
    z-index: 100;

  }
  
  .Btn.visible {
    opacity: 1;
    transform: translateY(0); /* Move to on-screen position */
  }
  
  .arrow path {
    fill: white;
  }
  
  .text {
    font-size: 0.7em;
    width: 100px;
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -18px;
    opacity: 0;
    transition: opacity 0.7s ease;
  }
  
  .Btn:hover .text {
    opacity: 1;
  }
  
  .Btn:hover .arrow {
    animation: slide-in-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  