/* General Styles */
.styles-page {
    font-family: 'Arial', sans-serif;
    padding: 40px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .styles-page h1 {
    font-size: 25px;
    color: #333;
    margin-bottom: 40px;
  }
  .cerca{
    color: #007bff;
    font-size: 28px;
  }
  /* Container for the Style Cards */
  .styles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
  }
  
  /* Style Card */
  .style-card {
    position: relative;
    max-width: 300px;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .style-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
  
  .style-image {
    width: 100%;
    height: auto;
    display: block;
  }
.chic-design {
    height: auto;
  }
  
  /* Overlay for text */
  .style-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    text-align: center;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .style-card:hover .style-overlay {
    transform: translateY(0);
  }
  
  .style-overlay h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .style-overlay p {
    font-size: 1rem;
  }
 
  /* Responsive Styles */
  @media (max-width: 768px) {
    .styles-container {
      flex-direction: column;
      align-items: center;
    }
  }
  