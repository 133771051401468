/* ContactUs.css */

.contact-us {
    padding: 2rem;
    background-color: #f8f9fa; /* Light background for modern look */
    border-radius: 8px;
}

.contact-header {
    margin-bottom: 2rem;
}

.contact-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.contact-form {
   flex: 1;
}

.contact-form h2 {
    margin-bottom: 1rem;
}

.form-group {
    margin-bottom: 1rem;

}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}
.form-group input{
  height: 50px;
}
.form-group input:focus,
.form-group textarea:focus {
    border-color: #007ACC;
    outline: none;
}

.btn-submit {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: #007ACC;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-submit:hover {
    background-color: #005bb5;
}
  
.contact-info {
    margin-top: 2rem;
}

.contact-info p {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.contact-img {
    width: 100%;
    max-width: 500px;
}

.color {
    color: #007ACC;
}

.mail a {
    color: black;
    text-decoration: none;
    font-size: 20px;
    padding: 10px;
}

.blue {
    color: #007ACC;
    margin-right: 10px;
}

.contact-info p {
    font-size: 20px;
    text-align: center;
}

.error {
  color: red;
  font-size: 0.875rem; /* Adjust size as needed */
  margin-top: 0.25rem; /* Space between input and error message */
  padding: 0.5rem;
  font-weight: 500;
}

/* Alert container styles */
.alert-container {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the alert both vertically and horizontally */
  z-index: 1050;
  width: 90%;
  max-width: 700px; /* Max-width for larger screens */
  border-radius: 8px; /* Rounded corners */
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  padding: 1rem; /* Padding inside the alert */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Alert show animation */
.alert-show {
  opacity: 1;
  transform: translate(-50%, -50%); /* Keep centered during show */
}

/* Alert hide animation */
.alert-hide {
  opacity: 0;
  transform: translate(-50%, -50%) translateY(-30px); /* Centered and hide with vertical movement */
}

/* Success alert styles */
.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Error alert styles */
.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Alert content styles */
.alert-custom {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center;
  padding: 1rem;
}

/* Icon styles */
.alert-custom .me-2 {
  font-size: 2rem; /* Large icon size */
  margin-bottom: 0.5rem; /* Space below icon */
}

/* Message styles */
.alert-message {
  font-size: 1.2rem; /* Larger font size */
  font-weight: 500;
  text-align: center; /* Center align text */
  line-height: 1.4; /* Improve readability */
}
@media (max-width: 992px) {
  .alert-container {
    width: 90%; /* Adjust width for tablets */
    max-width: 90%; /* Max-width for tablets */
    top: 30%;
    left: 50%;
  }
}
/* Responsive adjustments */
@media (max-width: 576px) {
  .alert-container {
    width: 95%; /* Adjust width for smaller screens */
    max-width: 95%; /* Further reduce max-width for very small screens */
  }
  
  .alert-custom .me-2 {
    font-size: 1.5rem; /* Smaller icon on small screens */
  }
  
  .alert-message {
    font-size: 1rem; /* Slightly smaller text on small screens */
  }
}

@media (max-width: 768px) {
  .alert-container {
    width: 90%; /* Adjust width for tablets */
    max-width: 90%; /* Max-width for tablets */
    top: 30%;
    left: 50%;
  }
  
  .alert-custom .me-2 {
    font-size: 1.75rem; /* Smaller icon on tablets */
  }
  
  .alert-message {
    font-size: 1.1rem; /* Slightly smaller text on tablets */
  }
  
}

